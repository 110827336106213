<template>
  <div>
    view
  </div>
</template>

<script setup lang='ts'>


</script>

<style scoped>

</style>